// Font mixin //

@mixin font($font-family, $font-size: 10px, $font-weight: 400, $color: $black, $font-style: normal, $tt: inherit) {
    font-family: $font-family;
    font-size: $font-size;
    font-weight: $font-weight;
    font-style: $font-style;
    color: $color;
    text-transform: $tt;
    line-height: 1.5 * $font-size;
    text-decoration: none;
}

// Media query mixin //

@mixin phone {
  @media (max-width: #{$tablet-width - 1px}) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: #{$tablet-width}) and (max-width: #{$desktop-width - 1px}) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: #{$desktop-width}) {
    @content;
  }
}

@mixin mediaMinWidth($min: 0px) {
  @media (min-width: #{$min}) {
    @content;
  }
}

// Flex //

@mixin flex($jc: center, $ai: center) {
  display: flex;
  justify-content: $jc;
  align-items: $ai;

  @include phone {
    flex-direction: column;
  }

  @include tablet {
    flex-direction: column;
  }
}

// Container //

@mixin container($width: $desktop-width) {
  width: $width;
  margin: auto;

  @include phone {
    width: 90%;
  }

  @include tablet {
    width: 80%;
  }
}

// BEM modifier

@mixin modifier($name) {
    $self : &;
    &-#{$name} {
        @extend #{$self};
        @content;
    }
}

// Button

@mixin button() {
    @include font('Raleway', 14px, 600, $color4);
    padding: 10px 15px;
    border: 2px solid $color4;
    border-radius: 4px;
    transition: 0.25s;

    &:hover {
        @include font('Raleway', 14px, 600, $white);
        background-color: $color4;
        transition: 0.25s;
    }
}


// Wysiwyg //

@mixin wysiwyg($color, $titleColor : $color1, $fontSize : 14px, $font : $font1) {

  p {
    @include font($font, $fontSize, 400, $color, normal, inherit);
  }

  em {
    font-style: italic;
  }

  strong {
    font-weight: bold;
  }

  blockquote p {
    @include font($font, $fontSize + 2px , 400, $color, italic, inherit);
    border-left: 4px solid $titleColor;
    padding: 5px 0px 5px 15px;
  }

  a {
    @include font($font, $fontSize, 400, $color4, normal, inherit);
    text-decoration: underline;

    &:hover {
        color: $titleColor;
    }
  }

  ul {
    margin-top: 20px;
  }

  li {
    @include font($font, $fontSize, 400, $color, normal, inherit);
    list-style: disc;
    margin: 5px 0px 5px 25px;
  }

  .button-white {
    @include font($font, $fontSize, 400, $white, normal, uppercase);
    border: 2px solid $white;
    padding: 10px 20px;
  }

  .button {
    @include font($font, $fontSize, 400, $color2, normal, uppercase);
    border: 2px solid $color2;
    padding: 10px 20px;
  }

  .h1 {
      @include font($font2, 30px, 800, $titleColor);
      margin-bottom: 35px;
  }

  .h2 {
    @include font($font, 42px, 600, $titleColor, normal, uppercase);
  }

  .h3 {
    @include font($font, 22px, 400, $titleColor, normal, inherit);
  }

  .h4 {
    @include font($font, 18px, 400, $titleColor, normal, uppercase);
  }

  .h5 {
    @include font($font, $fontSize + 1px , 400, $titleColor, normal, inherit);
    padding-left: 15px;
  }
}
