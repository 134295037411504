// HEADER //


.header {
  display: flex;
  height: 75px;
  border-bottom: 6px solid $white;

  &_container {
    width: 1140px;
    background: url("./img/background.jpg") no-repeat;
    background-size: cover;
  }

  &_line {
    margin: 11px 12px 10px 64px;
    height: 50px;
    border: 2px solid $white;
    position: relative;
  }

  &_rect {
    position: absolute;
    top: 50%;
    left: -20px;
    transform: translateY(-50%);
    background-color: $white;
    width: 225px;
    height: 30px;
    line-height: 30px;
    padding: 0 5px;
    text-align: center;
    @include font('CalsonGraph', 21px, 400, $black, normal, inherit);
  }

  &_red {
    color: $pink2;
  }

  &_quote {
    display: flex;
    flex-direction: column;
    width: 300px;
    background-color: $pink;
    padding: 12px 20px;
    box-sizing: border-box;
    @include font('CalsonPro', 14px, 400, $black, italic, inherit);
    line-height: 18px;
  }

  &_sign {
    @include font('CalsonPro', 14px, 600, $black, italic, inherit);
  }
}
