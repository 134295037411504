// Responsive sizes
$mobile-width: 425px;
$tablet-width: 768px;
$desktop-width: 1140px;

// Colors
$white: #ffffff;
$black: #222222;

$pink: #FFEFEF;
$pink2: #F06565;

// Font
$font1: 'Raleway', sans-serif;
$font2: 'C&T', sans-serif;
