// Calson Graph

@font-face {
   font-family: 'CalsonGraph';
   src: url('./font/CaslonGraphique-Opti.otf');
   font-weight: 400;
   font-style: normal;
}

// Calson Pro

@font-face {
   font-family: 'CalsonPro';
   src: url('./font/ACaslonPro-Regular.otf');
   font-weight: 400;
   font-style: normal;
}

@font-face {
   font-family: 'CalsonPro';
   src: url('./font/ACaslonPro-Italic.otf');
   font-weight: 400;
   font-style: italic;
}

@font-face {
   font-family: 'CalsonPro';
   src: url('./font/ACaslonPro-Semibold.otf');
   font-weight: 600;
   font-style: normal;
}

@font-face {
   font-family: 'CalsonPro';
   src: url('./font/ACaslonPro-SemiboldItalic.otf');
   font-weight: 600;
   font-style: italic;
}

@font-face {
   font-family: 'CalsonPro';
   src: url('./font/ACaslonPro-Bold.otf');
   font-weight: 800;
   font-style: normal;
}

// Raleway

@font-face {
   font-family: 'Raleway';
   src: url('./font/Raleway-Regular.otf');
   font-weight: 400;
   font-style: normal;
}

@font-face {
   font-family: 'Raleway';
   src: url('./font/Raleway-Medium.otf');
   font-weight: 500;
   font-style: normal;
}

@font-face {
   font-family: 'Raleway';
   src: url('./font/Raleway-SemiBold.otf');
   font-weight: 600;
   font-style: normal;
}

@font-face {
   font-family: 'Raleway';
   src: url('./font/Raleway-Light.otf');
   font-weight: 300;
   font-style: normal;
}

@font-face {
   font-family: 'Raleway';
   src: url('./font/Raleway-Thin.otf');
   font-weight: 200;
   font-style: normal;
}
