input, textarea, button {
    outline: none;
}

.nav {
    width: 100%;
    height: 100px;
    display: flex;
    justify-content: center;

    &_logo {
        margin: 20px;
        width: 200px;
    }
}

.container {
    width: 1440px;
    margin: auto;
    margin-top: 20px;
    box-shadow: 0px 1px 4px rgba(255, 115, 115, 0.5);
}

.content {
    display: flex;
    height: 650px;
}

.step {
    width: 1140px;
    max-width: 1140px;
    box-sizing: border-box;
    padding: 25px 60px;
    @include font('Raleway', 14px, 400, $black, normal, inherit);
    // overflow: scroll;
    position: relative;

    &_first, &_second, &_third, &_fourth, &_fifth {
        display: flex;
        flex-direction: column;
        //position: relative;
        // padding-bottom: 100px;
        height: 600px;
    }

    &_editor {
        height: 150px;
    }

    &_duo {
        display: flex;
        justify-content: space-between;
    }

    &_duo30 {
        width: 30%;
    }

    &_duo50 {
        width: 49%;
    }

    &_duo70 {
        width: 70%;
    }

    &_title {
        @include font('CalsonGraph', 14px, 400, $black, normal, inherit);
        margin-bottom: 20px;
        background-color: $pink;
        border-radius: 4px;
        padding: 15px 20px;
        width: 200px;
        text-align: center;
    }

    &_text {
        @include font('Raleway', 14px, 400, $black, normal, inherit);
        margin-bottom: 20px;
    }

    &_inputText {
        @include font('CalsonGraph', 14px, 400, $black, normal, inherit);
        background-color: $pink;
        padding: 15px 20px;
        border: none;
        border-radius: 4px;
        width: 270px;
        box-sizing: border-box;
        margin-bottom: 20px;

        &::placeholder {
            @include font('CalsonGraph', 14px, 400, #8F8F8F, normal, inherit);
        }
    }

    &_inputTextarea {
        @include font('CalsonGraph', 14px, 400, $black, normal, inherit);
        background-color: $pink;
        padding: 15px 20px;
        border: none;
        border-radius: 4px;
        width: 100%;
        box-sizing: border-box;
        margin-bottom: 20px;

        &::placeholder {
            @include font('CalsonGraph', 14px, 400, #8F8F8F, normal, inherit);
        }
    }

    &_inputEditor {
        @include font('Raleway', 14px, 400, $black, normal, inherit);
        background-color: $pink;
        padding: 15px 20px;
        border: none;
        border-radius: 4px;
        width: 100%;
        box-sizing: border-box;
        margin-bottom: 20px;

        &::placeholder {
            @include font('Raleway', 14px, 400, #8F8F8F, normal, inherit);
        }
    }

    &_inputSelect {
        @include font('CalsonGraph', 14px, 400, $black, normal, inherit);
        background-color: $pink;
        border: none;
        border-radius: 4px;
        width: 150px;
        box-sizing: border-box;
        margin-bottom: 20px;
        height: 35px;
    }

    &_inputOption {
        padding: 15px 20px;
    }

    &_inputNumber {
        @include font('CalsonGraph', 14px, 400, $black, normal, inherit);
        background-color: $pink;
        padding: 15px 20px;
        border: none;
        border-radius: 4px;
        width: 100px;
        box-sizing: border-box;
        margin-bottom: 20px;

        &::placeholder {
            @include font('CalsonGraph', 14px, 400, #8F8F8F, normal, inherit);
        }
    }

    &_next {
        position: absolute;
        right: 0px;
        bottom: 0px;
        @include font('Raleway', 12px, 800, $white, normal, uppercase);
        background-color: #F06565;
        border-radius: 4px;
        box-sizing: border-box;
        border: none;
        padding: 15px 20px;
        cursor: pointer;
    }

    &_inputProfil {
        width: 160px;
        border: 2px solid #F06565;
    }

    &_inputTagsContainer {

    }

    .filepond--drop-label {
        color: #4c4e53;
    }

    .filepond--label-action {
        text-decoration-color: #babdc0;
    }

    .filepond--panel-root {
        border-radius: 2em;
        background-color: $pink;
        height: 1em;
    }

    .filepond--item-panel {
        background-color: #595e68;
    }

    .filepond--drip-blob {
        background-color: #7f8a9a;
    }

    &_inputTags {
        display: none;
    }

    .select2-search__field {
        @include font('CalsonGraph', 14px, 400, $black, normal, inherit);
        background-color: $pink;
        padding: 15px 20px;
        border: none;
        border-radius: 4px;
        width: 270px !important;
        box-sizing: border-box;
        margin-bottom: 20px;
        margin-right: 10px;
    }

    .select2-selection__choice {
        @include font('CalsonGraph', 14px, 400, $black, normal, inherit);
        background-color: #FFBBBB;
        padding: 15px 20px;
        border: none;
        border-radius: 4px;
        box-sizing: border-box;
        margin-bottom: 20px;
        margin-right: 10px;
    }

    .select2-selection__choice__remove {
        background-color: $white;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        display: inline-block;
        text-align: center;
        @include font('CalsonGraph', 14px, 400, $black, normal, inherit);
        margin-right: 10px;
        cursor: pointer;
    }

}

.select2-dropdown {
    opacity: 0 !important;
    display: none !important;
}

.select2-selection {
    outline: none;
}

.select2-container {
    width: 100% !important;
}

.select2-selection__rendered {
    width: 100%;
    display: flex;
    flex-wrap: wrap-reverse;
    flex-direction: row-reverse;
    justify-content: flex-end;
}
