// FOOTER //

.footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    width: 300px;
    background-color: $pink;
    padding-top: 40px;

    &_progress {
        display: flex;
        flex-direction: column;
        width: 220px;
        margin: 0 auto;
        margin-bottom: 200px;
    }

    &_item {
        display: flex;
        margin-bottom: 20px;
    }

    &_dot {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background-color: $white;
        border: 2px solid $pink2;
        cursor: pointer;

        &-active {
            background-color: #FFBBBB;
        }
    }

    &_itemName {
        @include font('Raleway', 14px, 600, $black, normal, inherit);
        margin-left: 20px;
    }

    &_post {
        width: 160px;
        margin-bottom: 20px;
        background-color: $white;
        border: none;
        text-align: left;
        padding: 10px 15px;
        padding-bottom: 12px;
        cursor: pointer;
        border-radius: 4px;
        @include font('Raleway', 14px, 600, #898989, normal, uppercase);
        line-height: 18px;
    }

    &_draft {
        width: 220px;
        margin-bottom: 20px;
        background-color: #4E4E4E;
        border: none;
        text-align: left;
        padding: 10px 15px;
        cursor: pointer;
        border-radius: 4px;
        text-align: center;
        @include font('Raleway', 12px, 600, $white, normal, uppercase);
    }

    &_info {
        @include font('CalsonPro', 14px, 600, $black, italic, inherit);
        text-align: center;
        margin: 10px 0;
    }
}
